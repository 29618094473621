import React from 'react';
import gpt3Logo from '../../logo.svg';
import './footer.css';
import { useRef, useState } from 'react';
import emailjs from "@emailjs/browser";

const Footer = () => {
  const formRef = useRef();

  const [form, setForm] = useState({
    name: '',
    email: '',
    mod_transport: '',
    message: '',
  })

  const [loading, setLoading] = useState(false)

  const handleChange = (e) => {
    const { name, value } = e.target;
    setForm({...form, [name]: value })
  }

  const handleSubmit = (e) => {
    e.preventDefault();
    setLoading(true);

    // template_v61j4nf
    // service_xgol5c6
    // 6gldn-jTuiO9J4TjR

    emailjs.send(
      "service_rzkkva8", 
      "template_a2k5rdk", 
      {
        from_name: form.name,
        to_name: "Aminaei Adina",
        from_email: form.email,
        to_email: "info@teenlogistics.ro",
        mod_transport: form.mod_transport,
        message: form.message,
      },
      "LZu7WyPJstPN5OsHp"
    ).then(() => {
      setLoading(false);
      alert("Multumim, va vom contacta in cel mai scurt timp posibil!")
      setForm({
        name: "",
        email: "",
        message: ""
      })
    }, (error) => {
      setLoading(false)
      console.log(error);
      alert("Ceva nu a mers! Va rugam sa incercati din nou!")
    })
  }

  return (<div className="gpt3__footer section__padding" id="contact">
    <div className="gpt3__footer-heading">
      <h1 className="gradient__text">Contact</h1>
    </div>

    <div className='gpt3__footer'>
      <button type="button" className='whatsapp' onClick={() => window.open("https://api.whatsapp.com/send/?phone=40722333035&text&type=phone_number&app_absent=0", "_blank")}><i className="fa fa-whatsapp"></i>Scrieti-ne pe WhatsApp</button>
    </div>

    <div className="gpt3__footer-content__input">
      <form ref={formRef} onSubmit={handleSubmit}>
        <div className='display-row'>
          <div className='display-column'>
            <input required type="text" name="name" value={form.name} onChange={handleChange} placeholder="Numele complet"/>
            <input required type="email" name="email" value={form.email} onChange={handleChange} placeholder="Adresa de mail"/>
            <input required type="text" name="mod_transport" value={form.mod_transport} onChange={handleChange} placeholder="Mod de transport"/>
          </div>
          <textarea required type="text" name="message" value={form.message} onChange={handleChange} placeholder="Detalii"/>
        </div>
        <button type="submit" className='gpt3__footer-content__input-button'>{loading ? "Se trimite..." : "Trimite"}</button>
      </form>
		</div>

    <div className="gpt3__footer-links gpt3__put-center">
      <div className="gpt3__footer-links_logo">
        <div className="gpt3__navbar-links_logo gpt3__features-container__feature-titlexx">
          <h1>TeenLogistics</h1>
        </div>
        <p>Servicii premium de curierat rapid</p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Links</h4>
        <p><a href="#track">Track My Order</a></p>
        <p><a href="#servicii">Servicii</a></p>
        <p><a href="#curierat">Curierat</a></p>
        <p><a href="#contact">Contact</a></p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Companie</h4>
        <p><a href="#about">Cine Suntem</a></p>
        <p><a href="#cert">Certificari</a></p>
      </div>
      <div className="gpt3__footer-links_div">
        <h4>Termeni & Conditii</h4>
        <p><a href="https://drive.google.com/file/d/1fbIh-y7V5LWyZQ0s5tup7qq0EgmcvNPO/view?usp=sharing" target="_blank">Conditii generale de furnizare a serviciilor postale</a></p>
        <p><a href="https://drive.google.com/file/d/1V9q_SLMSoBNFd4G6O6dSI9AQY2BGvIYn/view?usp=sharing" target="_blank">Notificare privind prelucrarea datelor personale</a></p>
        <p><a href="https://drive.google.com/file/d/1_5ienOSbMvjhCK9lUwVcFCgrnavXg3pc/view?usp=sharing" target="_blank">Prelucrarea datelor personale</a></p>
        <p><a href="https://drive.google.com/file/d/1bOsrjh_qxly5UDk2cebZkB8IQ7-ieYBb/view?usp=sharing" target="_blank">Politica de confidentialitate</a></p>
        <p><a href="http://www.anpc.gov.ro/" target="_blank">ANPC</a></p>
        <p><a href="http://www.ancom.org.ro/" target="_blank">ANCOM</a></p>
      </div>
    </div>

    <div className="gpt3__footer-copyright">
      <p>​© 2024 Teen Logistics Services</p>
      <p><a href="admin">Admin</a></p>
    </div>
  </div>);
};

export default Footer;
